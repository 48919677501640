const items = []
const content = '이상의 위하여, 청춘의 인간에 청춘의 것이다. 있는 위하여 그들의 있다. 거친 것이다.보라, 끝에 열락의 물방아 힘차게 창공에 이것이다. 불어 뜨거운지라, 이상이 원질이 꽃 말이다.'
const contentEn = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'

const pushItem = (id, title, content, contentEn) => {
  items.push({
    contentId: id,
    title: title,
    content: content,
    contentEn: contentEn,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  })
}

pushItem(
  'intro_service',
  '사업소개',
  '<p>이상의 위하여, 청춘의 인간에 청춘의 것이다. 있는 위하여 그들의 있다. 거친 것이다.보라, 끝에 열락의 물방아 힘차게 창공에 이것이다. 불어 뜨거운지라, 이상이 원질이 꽃 말이다.</p><blockquote><p>시와 강아지, 어머니, 차 나의 까닭입니다. 당신은 덮어 릴케 계집애들의 까닭입니다. </p><p>아름다운 보고, 노새, 봅니다. 이웃 한 추억과 듯합니다. 어머님, 나의 청춘이 별 부끄러운 오는 아스라히 차 버리었습니다.</p></blockquote><p><img src="https://placeimg.com/320/320/any" contenteditable="false"><img class="ProseMirror-separator" alt=""><br class="ProseMirror-trailingBreak"></p>',
  contentEn
)
pushItem('intro_platform', '플랫폼소개', content.repeat(3), contentEn.repeat(3))
pushItem('intro_labs', '연구실소개', content.repeat(5), contentEn.repeat(5))

export default {
  items,
  count: items.length
}
